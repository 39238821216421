import React, { Component } from "react";

export default class cellsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        id="contact"
        onmousewheel="myFunction()"
        style={{ display: this.props.disp }}
      >
        <div className="container">
          <h1>Contact Us</h1>
          <p>
            <b>Official Mail of CCA</b>
            <br />
            <a
              href="mailto:cca@nitdgp.ac.in"
              style={{ textDecoration: "none", color: "black" }}
            >
              cca@nitdgp.ac.in
            </a>
            <br />
            <br />
            <b>Rohan Agarwal</b> (Convener & Head)
            <br />
            <a
              href="tel:7384306338"
              style={{ textDecoration: "none", color: "black" }}
            >
              +91 73843-06338
            </a>
            <br />
            <a
              href="mailto:convenerandhead@ccanitd.in"
              style={{ textDecoration: "none", color: "black" }}
            >
              convenerandhead@ccanitd.in
            </a>
            <br />
            <br />
            <b>Nikhil Singh</b> (General Secretary)
            <br />
            <a
              href="tel:8707656247"
              style={{ textDecoration: "none", color: "black" }}
            >
              +91 87076-56247
            </a>
            <br />
            <a
              href="mailto:generalsecretary@ccanitd.in"
              style={{ textDecoration: "none", color: "black" }}
            >
              generalsecretary@ccanitd.in
            </a>
            <br />
            <br />
            <b>Kanishka Chauhan</b> (Vice President)
            <br />
            <a
              href="tel:7668094357"
              style={{ textDecoration: "none", color: "black" }}
            >
              +91 76680-94357
            </a>
            <br />
            <a
              href="mailto:vicepresident@ccanitd.in"
              style={{ textDecoration: "none", color: "black" }}
            >
              vicepresident@ccanitd.in
            </a>
            <br />
            <br />
            <b>Harshit Rai</b> (Treasurer)
            <br />
            <a
              href="tel:7905155951"
              style={{ textDecoration: "none", color: "black" }}
            >
              +91 79051-55951
            </a>
            <br />
            <a
              href="mailto:treasurer@ccanitd.in"
              style={{ textDecoration: "none", color: "black" }}
            >
              treasurer@ccanitd.in
            </a>
          </p>
        </div>
        <footer
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Credits: Web, Design & Creative Team, CCA. 2019-2020. All Rights
          Reserved.
        </footer>
      </div>
    );
  }
}
