import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDfqVOZe_VBWG6OuPZVwAoX7dLbtreyBOM",
  authDomain: "cca-login-85039.firebaseapp.com",
  projectId: "cca-login-85039",
  storageBucket: "cca-login-85039.appspot.com",
  messagingSenderId: "988924306163",
  appId: "1:988924306163:web:79fd2f1a7245a3c6874d86",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();

export function googlesignin() {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      console.log(credential);
      // The signed-in user info.
      const user = result.user;
      console.log(user);
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
  return;
}

export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));
    return unsub;
  }, []);
  return currentUser;
};

export const logout = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      return true;
    })
    .catch((error) => {
      // An error happened.
      console.log(error);
      return false;
    });
};

// auth: AuthImpl {app: FirebaseAppImpl, config: {…}, currentUser: UserImpl, emulatorConfig: null, operations: Promise, …}
// displayName: "Arya Sah"
// email: "aryasah30@gmail.com"
// emailVerified: true
// isAnonymous: false
// metadata: UserMetadata {createdAt: '1642609995816', lastLoginAt: '1642610885302', lastSignInTime: 'Wed, 19 Jan 2022 16:48:05 GMT', creationTime: 'Wed, 19 Jan 2022 16:33:15 GMT'}
// phoneNumber: null
// photoURL: "https://lh3.googleusercontent.com/a-/AOh14Gj0FsrTdqnAEO-FFo-j2bwb1XLW4OBa1sFX_8ZLhw=s96-c"
// proactiveRefresh: ProactiveRefresh {user: UserImpl, isRunning: false, timerId: null, errorBackoff: 30000}
// providerData: [{…}]
// providerId: "firebase"
// reloadListener: null
// reloadUserInfo: {localId: 'S0v0he45xYgkhBi9MGp0SXcLBcG3', email: 'aryasah30@gmail.com', displayName: 'Arya Sah', photoUrl: 'https://lh3.googleusercontent.com/a-/AOh14Gj0FsrTdqnAEO-FFo-j2bwb1XLW4OBa1sFX_8ZLhw=s96-c', emailVerified: true, …}
// stsTokenManager: StsTokenManager {refreshToken: 'AFxQ4_ojTXlFPxGX1HcCMlA7UECeojPqMkz2sPv640zRYh_Ued…eMhjzKNtuopMYbdT8baHIm7vB8A1wn1Er8xl1yIxoTjq0QKTY', accessToken: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjQwMTU0NmJkMWRhMzA0ZD…VZzD5Q0jx1Q0qQciguv8H56eM5QqnvERYmn5aFGOPo5uMit9A', expirationTime: 1642614485928}
// tenantId: null
// uid: "S0v0he45xYgkhBi9MGp0SXcLBcG3"
// refreshToken: (...)
// [[Prototype]]: Object
