import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './css/postdetail.css';
import Wdct from './css/img/wdct.png';
import Core from './css/img/core.png';
import Rnd from './css/img/rnd.png';
import Ecell from './css/img/ecell.png';
import Robo from './css/img/robo.png';
import Posterror from './posterror';
import Loader2 from "../components/loader2";
import "./css/modal.css";
import { Modal } from "../components/Modal";






const PostDetail = () => {
  const { postId } = useParams();
  const[isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    //console.log('fetching');
    setIsLoading(true);
    axios({
      method: 'get',
      url: `https://ccaapi.ccanitd.in/api/cellposts/${postId}`,
    })
      .then((res) => {
        //console.log(res.data);
        setDataSource(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        //console.error(error)
        setIsLoading(false);
      })
  }, [postId]);
  function shareUrl(){
    navigator.clipboard.writeText(window.location.href);
    alert('Link Copied To Clipboard')
  }

  const openModal = () => {
    setShowModal(true);
  };

  const Logo = ({cname}) => {
    var logourl;
    if(cname === 'core'){
      logourl = Core;
    }
    else if(cname === 'wdct'){
      logourl = Wdct;
    }
    else if(cname === 'robo'){
      logourl = Robo;
    }
    else if(cname === 'rnd'){
      logourl = Rnd;
    }
    else{
      logourl = Ecell;
    }
    return(
      <a href={`/${cname}`}>
        <img src={logourl} alt="Cell_Logo" className="thelogo" />
      </a>
    );
  }
    if(isLoading){
      return(
        <Loader2 />
      );
    }
    else{
      return (<>
        {dataSource.length !== 0 ? <> 
        <h1 className="detail-header">
          <a href={ `/p${dataSource.cell_name}` }> &nbsp;<span className="fas fa-arrow-left" style={{ color: "white"}}> </span></a>
          &nbsp;&nbsp;
          <Logo cname={ dataSource.cell_name } />
          {dataSource.cell_name === 'rnd' ? 'R&D' : dataSource.cell_name}
        </h1>
        <div className="background">
          <div className="my-container">
            <h1 className="title">{dataSource.title}</h1>
            <div className="img-container" >
              <img src={dataSource.display_image} alt="post_image" className="img-fluid" />
            </div>
            <div className="share-container">
              <button className="share" onClick={shareUrl}>
                  Share <i class="fas fa-share-alt"></i>
              </button>
              {/*showModal ? <Modal setShowModal={setShowModal} title={dataSource.title} tags={`# ${dataSource.tags}`}/> : null*/}
            </div>

            
            <p>{dataSource.caption}</p>
            <hr className="thelines" />
            <p>{dataSource.description}<br/><span style={{color:'#0081a7',fontWeight:'bold',letterSpacing:'1px'}}>{dataSource.tags}</span></p>
            <br />
            {dataSource.facebook_url || dataSource.instagram_url || dataSource.linkedin_url ||dataSource.extra1 || dataSource.extra2 ? 
            <div id="my-child-container">
                <hr />
                <br />
                <div className="social-media-container">
                  {dataSource.facebook_url ? <a className="fb" href={dataSource.facebook_url}> <i class="fab fa-facebook-f"></i></a> : '' }
                  {dataSource.instagram_url ? <a className="ig" href={dataSource.instagram_url}> <i class="fab fa-instagram"></i></a> : '' }
                  {dataSource.linkedin_url ? <a className="li" href={dataSource.linkedin_url}> <i class="fab fa-linkedin-in"></i></a> : '' }
                </div>
                <br />
                <div className="extra-links-container"> 
                  {dataSource.extra1 ? <h3 className="link-tags"> <span className="tag-title"> Link 1 </span> <a className="tag-link" href={dataSource.extra1} target="_blank"> {dataSource.extra1} </a> </h3> : '' }
                  {dataSource.extra2 ? <h3 className="link-tags"> <span className="tag-title"> Link 2 </span> <a className="tag-link" href={dataSource.extra2} target="_blank"> {dataSource.extra2} </a> </h3> : '' }
                </div>
            </div>
            : ''}
            {dataSource.youtube_url ?
              <div id="my-child-container">
                <hr />
                <iframe src={dataSource.youtube_url} title="YouTube video player" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media;" allowFullScreen></iframe>
              </div> : <></>
            }
            {dataSource.pdf_url ?
              <div id="my-child-container">
                <hr />
                <iframe src={dataSource.pdf_url} title="pdf file" />
              </div> : <></>
            }
  
            {dataSource.video_drive_url ?
              <div id="my-child-container">
                <hr />
                <iframe src={dataSource.video_drive_url} className="img-fluid" title="Google Drive Video" allow="autoplay" />
              </div> : <></>
            }
          </div>
        </div>
        </>
        : <Posterror stylingid="postdoesnotexist" message="Post Does Not Exist !" />}

      </>)
      
    }
  
};

export default PostDetail;