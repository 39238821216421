import React, { useState } from 'react'

const MainArea = ({title,caption,cell_name,date_of_upload,pdf_url}) => {

    return (
        <div className="main-area">
     <div className="blog-post">
          <p className="blog-content">
                {title}
          </p>
          <p className="read-more">
            {(caption)}
          </p>

      </div>
      </div>
    )
}

export default MainArea
