import React from 'react'

const ImageArea = ({display_image,title,youtube_url,video_drive_url,caption}) => {
    return (
        <div className="image-container">

                {
                    !display_image ? "" : <div className="post__container">
                        <img src={display_image} className="card-image" alt="post_image" />
                    </div>
                }
        <h1 className="title">{title}</h1>
        <p className="caption">{caption}</p>
        </div>
    )
}

export default ImageArea
