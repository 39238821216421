import React,{useState} from 'react'
import Front from './Front'
import Back from './Back'
import './css/posts.css'

const Post = (props) => {
    const { id, title, description, display_image, caption, cell_name, date_of_upload, youtube_url,
        video_drive_url, pdf_url } = props.data;

    const [flipped, setFlipped]= useState(false);

    const flip=()=>{
        
    };

    return (
        <div onMouseEnter={()=> setFlipped(true)} onMouseLeave={()=> setFlipped(false)} className={"card-container" + (flipped ? " flipped" : "")}>
            <a href={`/posts/${id}`}>
                <Front display_image={display_image} title={title} caption={caption}/>
                <Back id={id} description={description} cell_name={cell_name}/>
            </a>
        </div>
    )
}

export default Post;

