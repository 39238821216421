import React, { useEffect } from "react";
import styled from "styled-components";
import "../css/modal-video.css";
import Carousel from "react-bootstrap/Carousel";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Section2 = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
}, []);
  return (
    <Wrapper className="aarohan__section" id="aarohan__section" style={{overflow:'hidden'}}>
      <Box 
        className="aarohan__section_box gpt3__whatgpt3" 
        data-aos="zoom-in"
        data-aos-duration="800"
      >
        <h2 className="gradient__text">About CCA</h2>
        <p className="box_p">
          CCA, Centre for Cognitive Activities, is the focal point where
          convergence of all technical and scientific endeavours of the students
          materialises. As the technical gymkhana of the institute, this club is
          the revolution which bridges the gap between knowledge and
          application. Bulk of the extracurricular activities held in the
          college all the year round are organised by the CCA.
        </p>
      </Box>
      <Carousel
        className="carousel_cont"
        fade
        controls={false}
        indicators={false}
        data-aos="fade-left"
        data-aos-duration="1200"
      >
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/cca_slider/one.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/cca_slider/two.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/cca_slider/three.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/cca_slider/four.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/cca_slider/five.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/cca_slider/six.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/cca_slider/seven.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/cca_slider/eight.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
      </Carousel>
    </Wrapper>
  );
};

export default Section2;

const Wrapper = styled.div`
  height: 100vh;
  left: 0;
  right: 0;
  padding: 70px 100px;
  background: url("./img/section_back.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
    padding: 50px 30px;
  }
`;

const Box = styled.div`
  flex: 0.4;
  border: none;
  width: 600px;
  margin-right: -150px;
  margin-top: 150px;
  padding: 50px;
  padding-bottom: 40px;
  z-index: 20;
  h2 {
    font-size: 50px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
    font-weight: 700;
    margin-top: 10px;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    color: #81afdd;
    text-overflow: wrap;
  }
  @media (max-width: 768px) {
    margin-right: 0px;
    margin-top: 0px;
    width: 100%;
    padding: 10px;
    h2 {
      font-size: 30px;
      margin-top: 20px;
      text-align: center;
    }
    p {
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

/*color: hsl(0, 0%, 41%);*/
