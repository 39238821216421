import React, { Component } from "react";
import Back_Button from "../components/back_button";
import { withRouter } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import "./css/cells_page.css";
import "./css/wdct.css";
import disableScroll from "disable-scroll";
import Loader2 from "../components/loader2";

var OnImagesLoaded = require("react-on-images-loaded");
var imgPF = "./img/members/pre_final/";
var imgF = "./img/members/final_year/";

class wdct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disp: "none",
      imgHeight: 255,
      imgWidth: 275,
      members: [
        {
          name: "SATYAJIT PAUL",
          post: "HEAD, WDCT",
          imgLocation: imgF + "SATYAJIT_PAUL.jpg",
        },
        {
          name: "RISHI MARODIA",
          post: "HEAD, WDCT",
          imgLocation: imgF + "RISHI_MARODIA.jpg",
        },
        {
          name: " GAYATRI AGARWAL",
          post: "HEAD, PUBLICATIONS",
          imgLocation: imgF + "GAYATRI_AGARWAL.jpg",
        },
        {
          name: "AMARDEEP RANJAN",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "AMARDEEP_RANJAN.jpg",
        },
        {
          name: "ANUSHKA MANDAL",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ANUSHKA_MANDAL.png",
        },
        {           
          name: "CHINTALA JAGADISH",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "CHINTALA_JAGADISH.png",
        },
        {           
          name: "ROHAN KUMAR",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "ROHAN_KUMAR.jpg",
        },
        {           
          name: "SAYANTAN SAMANTA",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "SAYANTAN_SAMANTA.jpg",
        },
        {           
          name: "SHREYA DEY SARKAR",
          post: "EXECUTIVE MEMBER",
          imgLocation: imgF + "SHREYA_DEY_SARKAR.png",
        },
        {
          name: "AASHIRYA CHANDAKA",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "AASHIRYA_CHANDAKA.png",
        },
        {
          name: "ABHISHEK RAJ",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "ABHISHEK_RAJ.jpg",
        },
        // {
        //   //PENDING
        //   name: "AMAN HARKARE",
        //   post: "SENIOR MEMBER",
        //   imgLocation: imgF + "AMAN_HARKARE.png",
        // },
        {
          name: "AMBEDKAR KUMAR",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "AMBEDKAR_KUMAR.jpg",
        },
        // {
        //   //PENDING
        //   name: "AVIRUP DAS",
        //   post: "SENIOR MEMBER",
        //   imgLocation: imgF + "AVIRUP_DAS.png",
        // },
        {
          name: "DNYANESH PATIL",
          post: "SENIOR MEMBER", 
          imgLocation: imgPF + "DNYANESH_PATIL.jpg",
        },
        {
          name: "MASUM AHMAD RAZA",
          post: "SENIOR MEMBER",
          imgLocation: imgPF + "MASUM_AHMAD_RAZA.jpg",
        },
        {
          name: "OBAIDULLAH AZAD",
          post: "SENIOR MEMBER", 
          imgLocation: imgPF + "OBAIDULLAH_AZAD.png",
        },
        // {
        //   //PENDING
        //   name: "PAL SHEKHAR",
        //   post: "SENIOR MEMBER", 
        //   imgLocation: imgPF + "PAL_SHEKHAR.png",
        // },
        {
          name: "PRASANTHI SANDAKA",
          post: "SENIOR MEMBER", 
          imgLocation: imgPF + "PRASANTHI_SANDAKA.png",
        },
        {
          name: "SOUMYADEEP CHAKRABORTY",
          post: "SENIOR MEMBER", 
          imgLocation: imgPF + "SOUMYADEEP_CHATERJEE.jpg",
        },
        
      ],
    };
    disableScroll.on();
  }
  handleImageLoaded() {
    this.setState({
      loading: false,
      disp: "block",
    });
    disableScroll.off();
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  createList() {
    var i,
      j,
      k = 0,
      row = [],
      col = [];
    for (i = 0; i < Math.floor(this.state.members.length / 3); i++) {
      col.push(
        <div className="col-lg-3 col-lg-offset-2  col-sm-6">
          <div className="membercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{
                    height: this.state.imgHeight,
                    width: this.state.imgWidth,
                  }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );
      k++;
      for (j = 0; j < 2; j++) {
        col.push(
          <div className="col-lg-3 col-sm-6">
            <div className="membercard">
              <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
                <figure>
                  <img
                    src={this.state.members[k].imgLocation}
                    alt=""
                    style={{
                      height: this.state.imgHeight,
                      width: this.state.imgWidth,
                    }}
                  />
                  <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                    <path d="M 0,414 275,414 275,350 0,350 z" />
                  </svg>
                  <figcaption>
                    <h1>{this.state.members[k].name}</h1>
                    <p>{this.state.members[k].post}</p>
                  </figcaption>
                </figure>
              </a>
            </div>
          </div>
        );
        k++;
      }
      row.push(<div className="row no-gutter">{col}</div>);
      col = [];
    }

    /* IF the number of members is a multiple of 3 comment out this col.push part otherwise keep it */
    if (this.state.members.length % 3 !== 0) {
      col.push(
        <div className="col-lg-3 col-lg-offset-2 col-sm-6">
          <div className="membercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{
                    height: this.state.imgHeight,
                    width: this.state.imgWidth,
                  }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );
    }

    k++;
    for (i = 1; i < this.state.members.length % 3; i++) {
      col.push(
        <div className="col-lg-3  col-sm-6">
          <div className="membercard">
            <a data-path-hover="M 0,414 275,414 275,240 0,280 z">
              <figure>
                <img
                  src={this.state.members[k].imgLocation}
                  alt=""
                  style={{
                    height: this.state.imgHeight,
                    width: this.state.imgWidth,
                  }}
                />
                <svg viewBox="0 0 275 414" preserveAspectRatio="none">
                  <path d="M 0,414 275,414 275,350 0,350 z" />
                </svg>
                <figcaption>
                  <h1>{this.state.members[k].name}</h1>
                  <p>{this.state.members[k].post}</p>
                </figcaption>
              </figure>
            </a>
          </div>
        </div>
      );
      k++;
    }
    row.push(<div className="row no-gutter">{col}</div>);
    col = [];

    return row;
  }


  render() {
    return (
      <OnImagesLoaded
        onLoaded={this.handleImageLoaded.bind(this)}
        timeout={200000}
        delay={2000}
      >
        {this.state.loading ? <Loader2 /> : null}

        <div className="landing" id="wdctLanding">
          <div>
            <Back_Button />

            <Button  className="cornerBtn" onClick={() => this.nextPath('/pwdct')}>
              WDCT Corner
            </Button>

            <div className="about_cell">
              <div className="about_back" style={{ color: "white" }}>
                <h1>Web, Design & Creative Team</h1>
                <h3>The technical cell of the club</h3>
              </div>
            </div>
          </div>
          <div id="anim" className="cell_details">
            <h1>Cell details</h1>
            <p className="cellinfo">
              A congregation of enthusiastic techno-crats, we manage the
              technical aspects of the club, to keep it up and running in the
              cyber front. From designing websites for fests to organising
              workshops for amateurs, we nurture the creative spurt in the club.
            </p>
            <h1 style={{ marginTop: "10%" }}>Web Development Team</h1>
            <p className="cellinfo text">
              {" "}
              This team is responsible for the development, design of the
              various websites associated with CCA, the Aarohan official website
              and various online publication and propaganda of the events of the
              club. It also devises interactive online events, which see about
              3000 online participants, for Aarohan, the Annual Techno-
              Management Fest of CCA.
            </p>
            <h1>Design Team</h1>
            <p className="cellinfo text">
              This creative team is entrusted with the development and designing
              of the various offline propaganda means for CCA as well as for
              Aarohan-The Annual Techno Management Fest of NIT Durgapur. It
              designs the sponsorship and talk show brochures for Aarohan,
              flyers, pamphlets, event posters and flexes for Aarohan.
            </p>

            <div className="container">
              <div className="heading faculty">
                <h1 style={{ color: "white" }}>Meet the Members</h1>
              </div>

              {this.createList()}
            </div>
          </div>
        </div>
      </OnImagesLoaded>
    );
  }
}

export default wdct;
