import React from "react";
import ai2 from "../css/img/ai2.png";
import "../css/auditionportal.css";
import { googlesignin, useAuth } from "../../pages/firebase/firebase";

const Header = ({trackButton}) => {
  const scrollBottom = () => {
      const section = document.querySelector( '#form__section' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );    
  };
  const user = useAuth();
  return (
    <div className="gpt3__header section__padding" id="homeid">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">
          Welcome to Centre For Cognitive Activities&apos; Auditions
        </h1>
        <p>
          We are currently expanding our family and thus we are in search of
          fresh minds with grey cells stimulated to learn, create and evolve.
          Raise your hand if that’s you! Fill up the form below to let us know
          that you are interested in appearing for the audition rounds and want
          to be a part of us.
        </p>

        <div className="gpt3__header-content__input">
 {!user ? (
            <button
              type="button"
              onClick={() => {
                googlesignin();
              }}
            >
              Log in
            </button>
          ) : (
            <button type="button" onClick={scrollBottom}>
              Register Now
            </button>
          )}
        
            {/* <button type="button" onClick={scrollBottom}>
              Register Now
            </button> */}
        </div>
      </div>

      <div className="gpt3__header-image">
        <img src={ai2} />
      </div>
    </div>
  );
};

export default Header;
