import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from "react-paginate"
import Loader2 from "./loader2";
import Post from "./Post";
import CCAlogo from '../pages/css/img/cca-app-icon.png'
import './css/postlist.css'

const PostsList = ({cell_name,logo}) => {

    const [dataSource,setDataSource]=useState([]);
    const [loading, setLoading] = useState(false);  
  
    const [pageNumber,setPageNumber] = useState(0)
    const postsPerPage = 9
    const pagesVisited = pageNumber * postsPerPage;
    const displayCards = dataSource.slice(pagesVisited, pagesVisited + postsPerPage).map(data =>{
      return (
        <>
            <Post key={data.id} data={data} />  
        </>
      )
    })

    const pageCount= Math.ceil(dataSource.length /9);


    const onPageChange =({selected})=>{
      setPageNumber(selected)
    }
    useEffect(()=>{
      //console.log('fetching');
      setLoading(true);
      axios({
        method:'get',
        url:`https://ccaapi.ccanitd.in/api/cellposts/get_cell/${cell_name}`,
      })
      .then((res) => {
        //console.log(res.data);
        setDataSource(res.data);
        setLoading(false);
      })
      .catch((error) => {
        //console.error(error);
        setLoading(false);
      })      
    },[cell_name]);
    
    return (
        <>
            {loading ? <Loader2/>
              :
              <div className="page-container">
                <div className="navbar">

                <a href="/" className="abs-cca">
                <img src={CCAlogo} alt="cca image" className="thelogo" />
                </a>

                <div className="cell-container">
                  <a href={`/${cell_name}`}>
                  <img src={logo} alt="Cell_Logo" className="thelogo" />
                  </a>
                  <a href={`/${cell_name}`}>
                  <h1>
                    {cell_name === 'rnd' ? 'R&D' : cell_name} 
                  </h1>
                  </a>
                </div>

              </div>
              {dataSource.length !== 0 ?   // If no of posts is not equal to zero then display this block
              <>
              <div className="cards-section">
                 {displayCards}
              </div>
              <ReactPaginate 
                previousLabel={"Prev"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={onPageChange}
                containerClassName={"paginationBtns"}
                previousLinkClassName={"previousBtn"}
                nextLinkClassName={"nextBtn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
              </>: // if posts are zero then display this one
              <>
              <div className="error-container">
                Posts Coming Soon. <span className="block455px"> Stay Tuned ! </span>
              </div>
              </>}
              </div>

            }
        </>
    )
}

export default PostsList;
