import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Container, Row, Col, Spinner } from "react-bootstrap";
import Modal from "./Modal";
import { useAuth } from "../../pages/firebase/firebase";

const SITE_KEY = "6LeK3F0cAAAAAELH7R-yCDyDuKuK57DnNRqUmh6C";

const Form = () => {
  const user = useAuth();
  const [name, setName] = useState("");
  const [roll, setRoll] = useState("");
  const [email, setEmail] = useState(user?.email);
  const [gender, setGender] = useState("");
  const [contactNum, setContactNum] = useState("");
  const [department, setDepartment] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resType, setResType] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [formVisible, setFormVisible] = useState(true);
  const [cca1, setCca1] = useState("");
  const [cca2, setCca2] = useState("");
  const [core1, setCore1] = useState("");
  const [rnd1, setRnd1] = useState("");
  const [rnd2, setRnd2] = useState("");
  const [rnd3, setRnd3] = useState("");
  const [wdct1, setWdct1] = useState("");
  const [wdct2, setWdct2] = useState("");
  const [wdct3, setWdct3] = useState("");
  const [wdct4T, setWdct4T] = useState("");
  const [wdct4L, setWdct4L] = useState("");
  const [robo1, setRobo1] = useState("");
  const [robo2, setRobo2] = useState("");
  const [ecell1, setEcell1] = useState("");
  const [ecell2, setEcell2] = useState("");

  function checkBrowser() {
    let browserName = "";
    let userAgent = navigator.userAgent;
    if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else {
      browserName = "No browser detection";
    }
    // Detect Chrome
    // console.log(browserName)
    return browserName;
  }

  const randomStringFunc = () => {
    if (checkBrowser() != "edge") return "off";
    let length = 6;
    const characters = "abcdefghijklmnopqrstuvwxyz";
    let randomString = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      randomString += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    return randomString;
  };

  const submitForm = (event) => {
    event.preventDefault();
    setIsLoading(true);
    //console.log(user);

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
      setError("Enter a valid Email");
      setTimeout(() => {
        const unsu = setError(null);
        return unsu;
      }, 5000);
      setIsLoading(false);
      return;
    }
    if (contactNum.length !== 10) {
      setError("Mobile Number should be of 10 digits");
      setTimeout(() => {
        const unsu = setError(null);
        return unsu;
      }, 5000);
      setIsLoading(false);
      return;
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token) => {
          sendData(token);
        });
    });
  };

  const sendData = (token) => {
    // let formData = new FormData();

    // formData.entr

    let formData = {
      email: email,
      uid: user?.uid,
      name: name,
      gender: gender,
      roll_no: roll,
      contact_number: contactNum,
      department: department,
      "g-recaptcha-response": token,
      token: "not-required",
    };

    // formData.append('email[]', email );
    // formData.append('uid[]', user?.uid );
    // console.log('Form Data',formData)
    // return
    // formData.append('name', name );
    // formData.append('gender', gender);
    // formData.append('roll_no', roll );
    // formData.append('contact_number', contactNum );
    // formData.append('department', department);
    // formData.append('g-recaptcha-response', token);
    // formData.append('token', "not-required" );
    console.log("form data", formData);
    // return
    var config = {
      method: "post",
      // url: "https://ccaaudition.ccanitd.in/api/auditions",
      url: "https://ccaaudition.ccanitd.in/api/auditionscc244b9737c2b6ef26bd0f7827653c9d27c10b7c",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        //console.log("axios", response.data);
        if (response.status == 201) {
          setResType("success");
        } else {
          let r = response.data;
          if (email != r.email && r.email != null) {
            setResType("exists");
          } else if (
            contactNum != r.contact_number &&
            r.contact_number != null
          ) {
            setResType("exists");
          } else if (user.uid != r.uid && r.uid != null) {
            setResType("exists");
          } else {
            setResType("error");
          }
        }
        setIsLoading(false);
        setIsOpen(true);
      })
      .catch(function (error) {
        //console.log(error);
        setResType("error");
        setIsLoading(false);
        setIsOpen(true);
      });
  };

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document?.body?.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        console.log("Script loaded!");
      }
    );
  }, []);
  useEffect(() => {
    console.log("user", user);
    setName(user?.displayName);
    setEmail(user?.email);
  }, [user]);
  return (
    <>
      {user && (
        <section
          id="form__section"
          className="formSection py-3 px-2 px-md-3 px-lg-5"
        >
          <Container className="contactForm p-3">
            <form onSubmit={submitForm}>
              <h2 className="gradient__text text__bold">
                CCA Auditions 2024 - Round 1
              </h2>
              <Row className="mb-5 mt-5">
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  className="form-group mb-5 offset-lg-1"
                >
                  <label htmlFor="name">Full Name *</label>
                  <input
                    className="form-control"
                    type="text"
                    autocomplete={randomStringFunc()}
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Col>
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  className="form-group mb-5 offset-lg-1"
                >
                  <label htmlFor="email">Email *</label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    // readonly
                    id="email"
                    autocomplete={randomStringFunc()}
                    value={email}
                    // onChange={(e)=>setEmail(e.target.value)}
                    required
                  />
                </Col>
              </Row>

              <Row className="mb-5">
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  className="form-group mb-5 offset-lg-1"
                >
                  <label htmlFor="roll">Roll Number *</label>
                  <input
                    className="form-control"
                    type="text"
                    autocomplete={randomStringFunc()}
                    name="roll"
                    id="roll"
                    value={roll}
                    onChange={(e) => setRoll(e.target.value)}
                    required
                  />
                </Col>

                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  className="form-group mb-5 offset-lg-1"
                >
                  <label htmlFor="contactNum">WhatsApp Phone Number *</label>
                  <input
                    className="form-control"
                    type="number"
                    name="contactNum"
                    autocomplete={randomStringFunc()}
                    id="contactNum"
                    value={contactNum}
                    onChange={(e) => {
                      if (e.target.value.length == 11) return false;
                      setContactNum(e.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>

              <Row className="mb-5">
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  className="form-group mb-5 offset-lg-1"
                >
                  <label htmlFor="gender">Gender </label>
                  <select
                    required
                    className="form-control"
                    // autocomplete={randomStringFunc()}
                    id="gender"
                    name="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    {department === "" ? (
                      <option value="">--select--</option>
                    ) : (
                      <option value="">--reset--</option>
                    )}
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  className="form-group mb-5 offset-lg-1"
                >
                  <label htmlFor="department">Department *</label>
                  <select
                    required
                    className="form-control"
                    value={department}
                    // autocomplete={randomStringFunc()}
                    id="choice5"
                    name="choice5"
                    onChange={(e) => setDepartment(e?.target?.value)}
                  >
                    {department === "" ? (
                      <option value="">--select--</option>
                    ) : (
                      <option value="">--reset--</option>
                    )}
                    <option value="Computer Science And Engineering">
                      Computer Science And Engineering
                    </option>
                    <option value="Electrical Engineering">
                      Electrical Engineering
                    </option>
                    <option value="Electronics Communication Engineering">
                      Electronics Communication Engineering
                    </option>
                    <option value="Mechanical Engineering">
                      Mechanical Engineering
                    </option>
                    <option value="Civil Engineering">Civil Engineering</option>
                    <option value="Chemical Engineering">
                      Chemical Engineering
                    </option>
                    <option value="Biotechnology Engineering">
                      Biotechnology Engineering
                    </option>
                    <option value="Metallurgical and Materials Engineering">
                      Metallurgical and Materials Engineering
                    </option>
                    <option value="Integrated Chemistry">
                      Integrated Chemistry
                    </option>
                  </select>
                </Col>
              </Row>

              {error && (
                <div
                  className="mb-3 text-center py-2"
                  style={{ color: "#ff8a71" }}
                >
                  {error}
                </div>
              )}

              <div className="mt-3 text-center mb-4">
                {isLoading ? (
                  <Spinner animation="border" variant="info" size="lg" />
                ) : (
                  <Button
                    className="customBtn"
                    variant="info"
                    type="submit"
                    size="lg"
                  >
                    Submit
                  </Button>
                )}
              </div>
            </form>
          </Container>
        </section>
      )}
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="section__margin modal_container">
          <div className="modal_right_container">
            {resType === "success" ? (
              <>
                <h1 className="gradient__text">Congratulations !</h1>
                <p className="modal_right_p">
                  We have successfully received your Round-1 submission for CCA
                  Auditions 2024 and will contact you very soon.
                  <br />
                  <br />
                  Join the WhatsApp group if you haven't, through the link below
                  for further updates and information regarding the auditions.
                  <br />
                  <a
                    href="https://chat.whatsapp.com/IaapkwYGVo663Rzw6tT4z6"
                    target="blank"
                    style={{
                      textDecoration: "underline",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    Join WhatsApp Group
                  </a>
                  <br />
                </p>
              </>
            ) : resType === "exists" ? (
              <>
                <h1 className="gradient__text">Already Submitted !</h1>
                <p className="modal_right_p">
                  You have already submitted the response for Round-1 with this
                  account or mobile number. We will contact you very soon.
                  <br />
                  <br />
                  Join the WhatsApp group if you haven't, through the link below
                  for further updates and information regarding the auditions.
                  <br />
                  <a
                    href="https://chat.whatsapp.com/IaapkwYGVo663Rzw6tT4z6"
                    target="blank"
                    style={{
                      textDecoration: "underline",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    Join WhatsApp Group
                  </a>
                  <br />
                </p>
              </>
            ) : (
              <>
                <h1 className="gradient__text">Could Not Register !</h1>
                <p className="modal_right_p">
                  Please try again after some time.
                  <br />
                </p>
              </>
            )}
          </div>
          <h2 onClick={() => setIsOpen(false)} style={modalClose}>
            X
          </h2>
        </div>
      </Modal>
    </>
  );
};

const modalClose = {
  position: "absolute",
  top: "-7%",
  right: "-6%",
  color: "#81afdd",
  margin: "10px",
  fontSize: "30px",
  cursor: "pointer",
};
export default Form;
