import React, { Component } from "react";

export default class homeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="home">
        <div className="inner_home">
          <h1>Centre for Cognitive Activities</h1>
          <p>
            CCA, Centre for Cognitive Activities, the largest and oldest technical club of NIT Durgapur, is the focal point where the convergence of all technical and scientific endeavors of the students materializes. Founded in 2003, this club aims to enhance the technical and managerial skills of the students from the beginning. Intending to bridge the gap between knowledge and application, CCA organizes diverse events covering the various domains throughout the year that stimulate the gray cells of the students, thereby proving to be an asset to them.
          </p>
        </div>
      </div>
    );
  }
}
