import ReactDOM from "react-dom";
const Modal = ({ children, isOpen, setisOpen }) => {
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <>
      <div style={overlay_styles}>
        <div style={modal_styles}>{children}</div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default Modal;

const modal_styles = {
  position: "fixed",
  display: "flex",
  padding: "50px",
  zIndex: 2000,
};
const overlay_styles = {
  position: "fixed",
  top: 0,
  display: "flex",
  left: 0,
  right: 0,
  bottom: 0,
  disply: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#011224d9",
  zIndex: 2000,
};
