import React from 'react'
import './css/posterror.css'

function Posterror(props) {
    return (
        <>
            <div className="d-flex flex-column justify-content-center w-100 h-100 the-error-container">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h1 id={props.stylingid} className="message fw-light text-white m-0">{props.message}</h1>
                </div>
            </div>
        </>
    )
}

export default Posterror
