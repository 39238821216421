import React, { useState, useRef } from "react";

import { Image, CloseButton, Dropdown } from "react-bootstrap";

import Popover from "react-bootstrap/Popover";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { googlesignin, logout, useAuth } from "../../pages/firebase/firebase";
import "../css/auditionportal.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const user = useAuth();
  console.log('user',user)
  return (
    <>
      <div className="gpt3__navbar">
        <div className="gpt3__navbar-links">
          <a className="gpt3__navbar-links_logo" href="/">
            <img src="img/ccalogo.png" />
            &nbsp;{" "}
            <p className="gradient__text">Centre For Cognitive Activities</p>
          </a>
        </div>
        <div className="gpt3__navbar-sign">
          {user &&
            (user.photoURL ? (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    <span claName="modal_right_p padding-medium">{user?.displayName}</span>
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <Image
                    ref={ref}
                    {...triggerHandler}
                    className="avatar"
                    alt="user"
                    roundedCircle
                    src={`https://api.dicebear.com/5.x/initials/svg?seed=${user?.displayName}`}
                  ></Image>
                )}
              </OverlayTrigger>
            ) : (
              <p style={{padding:10}}>{user?.displayName}</p>
            ))}
          {!user ? (
            <button
              type="button"
              onClick={() => {
                googlesignin();
              }}
            >
              Log In
            </button>
          ) : (
            <button
              onClick={() => {
                logout();
              }}
              type="button"
            >
              Logout
            </button>
          )}
        </div>
        {user ? (
          <Dropdown className="dropdown">
            <Dropdown.Toggle className="dropdown-basic">
              <Image
                className="avatar"
                alt="user"
                roundedCircle
                src={`https://api.dicebear.com/5.x/initials/svg?seed=${user?.displayName}`}
              ></Image>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu">
              <Dropdown.Item className= "flex-center">{user?.displayName}</Dropdown.Item>
              <Dropdown.Item  className= "flex-center">
                <button
                  className="button-drop"
                  onClick={() => {
                    logout();
                  }}
                  type="button"
                >
                  Logout
                </button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
      </div>
    </>
  );
};

export default Navbar;
