import React, { useState, useRef } from "react";
import { useAuth } from "./firebase/firebase.js";

import {
  Form,
  Section,
  Section2,
  Header,
  Navbar,
  CellInfo,
  Results,
  Footer,
} from "../components/auditionportal";

const AuditionPortal = () => {
  const user = useAuth();
  const trackButton =  React.createRef();
  return (
    <>
      <div className="gradient__bg">
        <Navbar />
        <Header trackButton={trackButton}/>
      </div>
      <Section2 />
      <Section />
      <CellInfo />
      {/* <Results trackButton={trackButton}/> */}
      <Form />
      <Footer />
    </>
  );
};

export default AuditionPortal;
