import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-copyright">
      <p>
        Made with{" "}
        <span>
          <FontAwesomeIcon icon={["fas", "heart"]} color="red" />
        </span>{" "}
      </p>
      <p>
        by Web Design & Creative Team (WDCT),
        <br />
        CCA
      </p>
    </div>
  </div>
);

export default Footer;
