import React, { Component } from "react";

// import $ from "jquery";
// import '../pages/js/min/members';

export default class logos extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="cca">
          <a href="/">
            {" "}
            <img id="logoImg" src="img/cca-app-icon.png" alt="" />{" "}
          </a>
        </div>
       
      </div>
    );
  }
}
