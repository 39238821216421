import React, { useState,useEffect } from "react";
import styled from "styled-components";
import "../css/modal-video.css";
import ModalVideo from "react-modal-video";
import Carousel from "react-bootstrap/Carousel";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Section = () => {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Wrapper 
      className="aarohan__section" 
      id="aarohan__section"
      style={{overflow:'hidden'}}
    >
      {/* <LeftImage /> */}
      <Carousel
        className="carousel_cont"
        fade
        controls={false}
        indicators={false}
        data-aos="fade-right"
        data-aos-duration="1200"
      >
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/aarohan_slider/one.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/aarohan_slider/two.JPG"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/aarohan_slider/three.JPG"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/aarohan_slider/four.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/aarohan_slider/five.JPG"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item" interval={1500}>
          <img
            className="carousel-img"
            src="/img/aarohan_slider/six.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
      </Carousel>
      <Box 
        className="aarohan__section_box gpt3__whatgpt3"
        data-aos="zoom-in"
        data-aos-duration="800"
      >
        <h2 className="gradient__text">The AAROHAN</h2>
        <p className="box_p">
          Aarohan,the second largest fest in Eastern India, the annual Techno
          Management festival of NIT Durgapur, It dares one to elevate his
          limits and to defy one's capacity. Aarohan hosts a galaxy of events
          aimed at boosting the technological and managerial skills inherent in
          today's youth and providing them with an opportunity to showcase their
          innovative ideas and thoughts.
          <span
            onClick={() => setOpen(true)}
            style={{
              display: "block",
              color: "white",
              "font-weight": "bold",
              cursor: "pointer",
            }}
          >
            Click here to know more.
          </span>
        </p>
      </Box>
      <Model isOpen={isOpen} setOpen={setOpen} />
    </Wrapper>
  );
};

const Model = ({ isOpen, setOpen }) => {
  return (
    <React.Fragment>
      <ModalVideo
        channel="youtube"
        autoplay
        theme="dark"
        isOpen={isOpen}
        videoId="j2hREu7yRIQ"
        onClose={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default Section;

const Wrapper = styled.div`
  height: 100vh;
  left: 0;
  right: 0;
  padding: 70px 100px;
  background: url("./img/section_back3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    padding: 50px 30px;
  }
`;

const Box = styled.div`
  flex: 0.4;
  border: none;
  width: 600px;
  margin-left: -150px;
  margin-top: 130px;
  padding: 50px;
  padding-bottom: 40px;
  z-index: 20;
  h2 {
    font-size: 50px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
    font-weight: 700;
    margin-top: 10px;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    color: #81afdd;
    text-overflow: wrap;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-top: 0px;
    width: 100%;
    padding: 10px;
    h2 {
      font-size: 30px;
      margin-top: 20px;
      text-align: center;
    }
    p {
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

/*color: hsl(0, 0%, 41%);*/
