import React from 'react';
import PostsList from "../components/PostsList";
import Wdct from '../pages/css/img/wdct.png'
import Core from '../pages/css/img/core.png'
import Rnd from '../pages/css/img/rnd.png'
import Ecell from '../pages/css/img/ecell.png'
import Robo from '../pages/css/img/robo.png'



export const WdctPosts = () => {
 
    return (
        <>
            <PostsList logo={Wdct} cell_name="wdct"/>
        </>
    )
}

export const CorePosts = () => {
 
    return (
        <>
            <PostsList logo={Core} cell_name="core"/>
        </>
    )
}

export const RoboPosts = () => {

    return (
        <>
            <PostsList logo={Robo} cell_name="robo"/>
        </>
    )
}

export const RndPosts = () => {
 
    return (
        <>
            <PostsList logo={Rnd} cell_name="rnd"/>
        </>
    )
}

export const EcellPosts = () => {
 
    return (
        <>
            <PostsList logo={Ecell} cell_name="ecell"/>
        </>
    )
}

