import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';

const SITE_KEY = "6LeK3F0cAAAAAELH7R-yCDyDuKuK57DnNRqUmh6C";

const Instructions = () =>{
    return(
        <p style={{textAlign: "justify"}}>
            <b>Note :</b><br />
            <ul>
                <li>For the youtube url, please provide the embed url.</li>
                <li>For the google drive pdf and video url, you need to provide embed link. Please follow below instructions.</li>
                <ol>
                    <li>Upload the file to your Google Drive account. Ensure that the file's share settings are set to Public; otherwise, the file cannot be shown.</li>
                    <li>Double click on the file in Google Drive to open it, click on "More actions" in the top right-hand corner (three vertical dots), and choose "Open in a new window."</li>
                    <li>In the new window, click again on "More actions" in the top right-hand corner (three vertical dots) and choose "Embed item...".</li>
                    <li>Copy the link from within the embed script, shown between the " " quotation marks. </li>
                    <li>Reference : <a href="https://help.brella.io/en/organizers/how-to-find-the-embed-link-of-a-file-from-google-drive" target='_blank'>Click here.</a></li>
                </ol>
            </ul>
        </p>
    );
}

const AlertMsg = ({isPosted}) =>{
    if(isPosted==='Yes'){
        return(
            <h5 className="text-center" style={{color: 'Green'}}>
                Post created successfully!
            </h5>
        );
    }
    else if(isPosted==='No-creds'){
        return(
            <h5 className="text-center" style={{color: 'Red'}}>
                Wrong Credentials!
            </h5>
        );
    }
    else if(isPosted==='No-others'){
        return(
            <h5 className="text-center" style={{color: 'Red'}}>
                Could not create the post due to incorrect details.
            </h5>
        );
    }
    else if(isPosted==='No'){
        return(
            <h5 className="text-center" style={{color: 'Red'}}>
                Could not create the post. Please try again.
            </h5>
        );
    }
    else{
        return null;
    }
}

const PostAdmin = () => {

    const[username, setUsername] = useState('');
    const[password, setPassword] = useState('');
    const[title, setTitle] = useState('');
    const[caption, setCaption] = useState('');
    const[image, setImage] = useState('');
    const[description, setDescription] = useState('');
    const[youTube, setYouTube] = useState('');
    const[video, setVideo] = useState('');
    const[pdf, setPdf] = useState(''); 
    const[linkedIn, setLinkedIn] = useState('');
    const[instagram, setInstagram] = useState('');
    const[facebook, setFacebook] = useState('');
    const[extra1, setExtra1] = useState('');
    const[extra2, setExtra2] = useState('');
    const[tags, setTags] = useState('');

    const[isLoading, setIsLoading] = useState(false);
    const[isPosted, setIsPosted] = useState('');

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
          const isScriptExist = document.getElementById(id);
       
          if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
              if (callback) callback();
            };
            document.body.appendChild(script);
          }
       
          if (isScriptExist && callback) callback();
        }
       
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
          console.log("Script loaded!");
        });
    }, []);

    const handleOnClick = (event) => {
        event.preventDefault();
        setIsLoading(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
            handleSubmit(token);
            //console.log(token);
          });
        });
    }


    const handleSubmit = (token) => {

        var data = new FormData();
        data.append('username', username);
        data.append('password', password);
        data.append('title', title);
        data.append('display_image', image, image.name);
        data.append('description', description);
        data.append('caption', caption);
        data.append('youtube_url', youTube);
        data.append('video_drive_url', video);
        data.append('pdf_url', pdf);
        data.append('linkedin_url', linkedIn);
        data.append('facebook_url', facebook);
        data.append('instagram_url', instagram);
        data.append('extra1', extra1);
        data.append('extra2', extra2);
        data.append('tags', tags);
        data.append('g-recaptcha-response', token);

        var config = {
        method: 'post',
        url: 'https://ccaapi.ccanitd.in/api/createpost',
        //url: 'http://127.0.0.1:8000/api/createpost',
        headers: { 
            'Content-Type': 'application/json', 
        },
        data : data
        };

        axios(config)
        .then(function (response) {
            //console.log(response.data);
            setIsLoading(false);
            setIsPosted('Yes');
            setUsername('');
            setPassword('');
            setTitle('');
            setCaption('');
            setImage('');
            setDescription('');
            setYouTube('');
            setFacebook('');
            setInstagram('');
            setVideo('');
            setPdf('');
            setLinkedIn('');
            setExtra2('');
            setExtra1('');
            setTags('');
        })
        .catch(function (error) {
            //console.log(error.response.data);
            setIsLoading(false);
            if(error.response){
                if(error.response.data.msg==='creds'){
                    setIsPosted('No-creds');
                }
                else{
                    setIsPosted('No-others');
                }
            }
            else{
                setIsPosted('No');
            }
        });
    }

    return (
        <div>
            <h2 className="text-center">Centre for Cognitive Activities (CCA)</h2>
            <h3 className="text-center mt-3">Create a Post</h3>
            <Container className="mt-5 mb-5">
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8}>
                        <Instructions />
                        <Form className="mt-3" onSubmit={handleOnClick}>
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>Username *</Form.Label>
                                <Form.Control type="text" placeholder="Enter Username" value={username} onChange={(e)=>setUsername(e.target.value)} required/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Password *</Form.Label>
                                <Form.Control type="password" placeholder="Enter Password" value={password} onChange={(e)=>setPassword(e.target.value)} required/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="title">
                                <Form.Label>Post Title *</Form.Label>
                                <Form.Control type="text" placeholder="Title" value={title} onChange={(e)=>setTitle(e.target.value)} required/>
                            </Form.Group>

                            <Form.Group controlId="image" className="mb-3">
                                <Form.Label>Post Image *</Form.Label>
                                <Form.Control type="file" accept="image/*" onChange={(e)=>setImage(e.target.files[0])} required/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="caption">
                                <Form.Label>Post Caption *</Form.Label>
                                <Form.Control type="text" placeholder="Caption" value={caption} onChange={(e)=>setCaption(e.target.value)} required/>
                            </Form.Group>

                            <Form.Group controlId="description" className="mb-3">
                                <Form.Label>Post Description (if any)</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Leave a description here"
                                    style={{ height: '100px' }}
                                    value={description} onChange={(e)=>setDescription(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="youtube">
                                <Form.Label>Youtube Url (if any)</Form.Label>
                                <Form.Control type="text" placeholder="Youtube url" value={youTube} onChange={(e)=>setYouTube(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="pdf">
                                <Form.Label>Drive Pdf Url (if any)</Form.Label>
                                <Form.Control type="text" placeholder="Drive url for pdf" value={pdf} onChange={(e)=>setPdf(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="video">
                                <Form.Label>Drive Video Url (if any)</Form.Label>
                                <Form.Control type="text" placeholder="Drive url for video" value={video} onChange={(e)=>setVideo(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="linkedIn">
                                <Form.Label>LinkedIn Url (if any)</Form.Label>
                                <Form.Control type="text" placeholder="LinkedIn Post Url" value={linkedIn} onChange={(e)=>setLinkedIn(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="instagram">
                                <Form.Label>Instagram Url (if any)</Form.Label>
                                <Form.Control type="text" placeholder="Instagram Post Url" value={instagram} onChange={(e)=>setInstagram(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="facebook">
                                <Form.Label>Facebook Url (if any)</Form.Label>
                                <Form.Control type="text" placeholder="Facebook Post Url" value={facebook} onChange={(e)=>setFacebook(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="extra1">
                                <Form.Label>Extra Url 1 (if any)</Form.Label>
                                <Form.Control type="text" placeholder="Extra Url" value={extra1} onChange={(e)=>setExtra1(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="extra2">
                                <Form.Label>Extra Url 2 (if any)</Form.Label>
                                <Form.Control type="text" placeholder="Extra Url" value={extra2} onChange={(e)=>setExtra2(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="tags">
                                <Form.Label>HashTags (if any)</Form.Label>
                                <Form.Control type="text" placeholder="e.g. #CCA etc." value={tags} onChange={(e)=>setTags(e.target.value)}/>
                            </Form.Group>
                            
                            <AlertMsg isPosted={isPosted} />

                            <div className="mt-3">
                                {isLoading ? 
                                    <Spinner animation="border" variant="info" size="lg"/>
                                    :
                                    <Button variant="info" type="submit" size="lg">Submit</Button>
                                }
                            </div>
                           
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PostAdmin;
