import React, { useState } from 'react';

const Back = ({id, cell_name, description}) => {
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const theReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 200) : text}...
      </p>
    );
  };
    return (
        <div className="back">
        <p id="thetext">
          <ReadMore>
            {description}
          </ReadMore>
        </p>
        <a href={`/posts/${id}`}>Read More</a>
        </div>
        
    )
}

export default Back;
