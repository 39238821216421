import React from 'react';
import ImageArea from './ImageArea';
import MainArea from './MainArea';

const Front = ({title,display_image,caption,cell_name,date_of_upload,youtube_url,video_drive_url,pdf_url}) => {
    return (
        <div className="front">
        <ImageArea display_image={display_image} />
        <MainArea caption={caption} title={title}/>
      </div>
    )
}

export default Front;

{/* <iframe width="914" height="514" src="https://www.youtube.com/embed/ow1QqW0jzTo?list=RDow1QqW0jzTo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
